.App {
  height: 100%;
  padding: 30px;
}

.App-content {
  padding: 30px;
  font-size: calc(10px + 1vmin);
  max-width: 600px;
}

.Content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  display: flex;
  max-width: 600px;

  padding: 30px 30px 0px 30px ;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.title {
  font-size: calc(10px + 2vmin);
  font-weight: 600;
  padding-bottom: 40px;
  color: rgba(0, 0, 0, 0.6);
}

.description {
  font-size: calc(10px + 0.5vmin);
  font-weight: 300;
  word-wrap: break-word; /* 単語単位で折り返し */
  color: rgba(0, 0, 0, 0.6);
  overflow-wrap: break-word; /* 単語単位で折り返し */
  white-space: pre-wrap;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.question {
  margin-top: 20px;
}
